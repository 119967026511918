define ('gmailUtils',[
    'module',
    'atmosphere',
    'settings',
    'underscore',
    'jquery',
    'logger',
    'marionette',
    'dateUtils'
], function(module, Atmosphere, Settings, _, $, Logger, Marionette, dateConverter) {
    'use strict';


    var instance = null;
    var CLIENT_ID = Settings.get('gmailClientId');
    var API_KEY = Settings.get('gmailAPIKey');

    // Array of API discovery doc URLs for APIs used by the quickstart
    var DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    var SCOPES = 'https://www.googleapis.com/auth/gmail.send';

    var GmailUtils = Marionette.Object.extend({
        connected: false,

        init: function() {
            this.paginationTokens = [];
            this.currentPaginationToken = null;
            window.gapi.load('client:auth2', _.bind(this.initClient, this));
        },

        initClient: function() {
            window.gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES
            }).then(_.bind(function () {
                // Listen for sign-in state changes.
                window.gapi.auth2.getAuthInstance().isSignedIn.listen(_.bind(this.updateSigninStatus, this));
                // Handle the initial sign-in state.
                this.updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
            }, this), _.bind(function() {
                //appendPre(JSON.stringify(error, null, 2));
            }, this));
        },

        updateSigninStatus: function(isSignedIn) {
            if (isSignedIn) {
                this.connected = true;
                instance.trigger('gmail_utils:loaded');
            } else {
                instance.trigger('gmail_utils:signInNeeded');
            }
        },

        signIn: function () {
            window.gapi.auth2.getAuthInstance().signIn();
        },

        getMessage: function (messageId, query) {
            var defer = $.Deferred();
            var request = window.gapi.client.gmail.users.messages.get({
                'userId': 'me',
                'id': messageId,
                'format': 'full'
            });
            request.execute(_.bind(function(m) {
                for (var i = 0 ; i < m.payload.headers.length ; i++) {
                    var cur = m.payload.headers[i];
                    switch (cur.name) {
                        case 'From': m.from = cur.value; break;
                        case 'To': m.to = cur.value; break;
                        case 'Subject': m.subject = cur.value; break;
                        case 'Date': m.date = cur.value; break;
                    }
                }
                if (m.from.indexOf(query) !== -1) {
                    m.direction = 'from';
                } else {
                    m.direction = 'to';
                }
                m.content = this.parseBody(m.payload, '');
                m.contentDoc = this.getBody(m.payload);
                m.time = dateConverter.toTimeFormatString(new Date(m.date));
                m.day = dateConverter.toDateFormatString(new Date(m.date));
                defer.resolve(m);
            }, this));

            return defer.promise();
        },

        parseBody: function(item, content) {
            if (item.body.data) {
                content += atob( item.body.data.replace(/-/g, '+').replace(/_/g, '/') );
            } else {
                if (item.parts) {
                    for (var i = 0 ; i < item.parts.length ; i++) {
                        content = this.parseBody(item.parts[i], content);
                    }
                }
            }
            return content;
        },

        listMessages: function(query, callback) {
            var getPageOfMessages = _.bind(function(request, result, completeResult) {
                request.execute(_.bind(function(resp) {
                    result = result.concat(resp.messages);
                    var nextPageToken = resp.nextPageToken;
                    if (nextPageToken) {
                        request = window.gapi.client.gmail.users.messages.list({
                            'userId': 'me',
                            'pageToken': nextPageToken,
                            'q': query
                        });
                        getPageOfMessages(request, result, completeResult);
                    } else {
                        var proms = [];
                        _.each(result, _.bind(function (r) {
                            if (r) {
                                proms.push(this.getMessage(r.id, query));
                            }
                        }, this));
                        Promise.all(proms).then(_.bind(callback, this));
                    }
                }, this));
            }, this);
            var initialRequest = window.gapi.client.gmail.users.messages.list({
                'userId': 'me',
                'q': query
            });
            getPageOfMessages(initialRequest, [], []);
        },

        listMessagesPaginated: function(query, direction, callback) {
            var tokenIndex;
            var effectiveToken = null;
            query = query?query:'';

            switch(direction) {
                case 'next':
                    tokenIndex = _.indexOf(this.paginationTokens,this.currentPaginationToken) + 1;
                    break;
                case 'prev':
                    tokenIndex = _.indexOf(this.paginationTokens,this.currentPaginationToken) - 1;
                    break;
                default:
                   tokenIndex = 0;
            }
            if (tokenIndex > 0 || tokenIndex <= this.paginationTokens.length - 1) {
                effectiveToken = this.paginationTokens[tokenIndex-1];
            }
            var initialRequest = window.gapi.client.gmail.users.messages.list({
                'userId': 'me',
                'maxResults': 20,
                'pageToken': effectiveToken,
                'q': 'in:inbox ' + query
            }),
              that = this;

            var getPageOfMessages = _.bind(function(request, result) {
                request.execute(_.bind(function(resp) {
                    result = result.concat(resp.messages);
                    that.currentPaginationToken = resp.nextPageToken;
                    var pageNr = _.indexOf(that.paginationTokens,that.currentPaginationToken);
                    if (pageNr === -1) {
                        that.paginationTokens.push(that.currentPaginationToken);
                    }
                    var mailboxChanged = -1;
                    for (var i = 0; i < that.paginationTokens.length; i++) {
                        if(!that.paginationTokens[i]) {
                            mailboxChanged = i;
                            break;
                        }
                    }
                    if (mailboxChanged !== -1) {
                        that.paginationTokens = _.rest(that.paginationTokens,mailboxChanged+1);
                    }
                    var proms = [];
                        _.each(result, _.bind(function (r) {
                            if (r) {
                                proms.push(this.getMessage(r.id,'') );
                            }
                        }, this));
                        Promise.all(proms).then(_.bind(callback, this));
                }, this));
            }, this);
            getPageOfMessages(initialRequest, []);
        },

        getBody: function (message) {
            var encodedBody = '';
            if(typeof message.parts === 'undefined' && message.body)
            {
                encodedBody = message.body.data;
            }
            else if (message.parts)
            {
                encodedBody = this.getHTMLPart(message.parts);
            }
            encodedBody = encodedBody.replace(/-/g, '+').replace(/_/g, '/').replace(/\s/g, '');
            return decodeURIComponent(window.escape(window.atob(encodedBody)));
        },

        getHTMLPart: function (arr) {
            for(var x = 0; x <= arr.length; x++)
            {
                if(typeof arr[x].parts === 'undefined')
                {
                    if(arr[x].mimeType === 'text/html')
                    {
                        return arr[x].body.data;
                    }
                }
                else
                {
                    return this.getHTMLPart(arr[x].parts);
                }
            }
            return '';
        },

        connect: function() {
            if (this.connected) {
                instance.trigger('gmail_utils:loaded');
            }
        },

        forwardReplyMail: function(operation,message, callback) {
            var email = '',
                subjectHeader = '';
            var  subject = _.findWhere(message.payload.headers,{name :'Subject'});

            subject = (subject.value)?subject.value:'';

            var op = (operation === 'f')?'Fw: ':'Re: ';
            subjectHeader = '=?UTF-8?B?$'+this.encode(op + subject)+'?=';

            var headersObj = {
                'To': _.findWhere(message.payload.headers,{name :'To'}).value,
                'Subject': subjectHeader
            };

            headersObj['content-type'] = 'text/html;';

            for(var header in headersObj)
                email += header += ': '+headersObj[header]+'\r\n';

            email += '\r\n' + message.payload.body;

            var request = {
                'userId': 'me',
                'resource': {
                    'raw': window.btoa(encodeURIComponent(email).replace(/%([0-9A-F]{2})/g,
                      function toSolidBytes(match, p1) {
                          return String.fromCharCode('0x' + p1);
                      })),
                    'threadId': message.threadId
                }
            };
            if (operation === 'r') {
                var messageId = _.findWhere(message.payload.headers,{name :'Message-ID'});
                var inReplyTo = _.findWhere(message.payload.headers,{name :'In-Reply-To'});
                if (inReplyTo) {
                    inReplyTo = inReplyTo.value + messageId?messageId.value:'';
                }
                var references = _.findWhere(message.payload.headers,{name :'References'});
                if (references) {
                    request.resource.References = references.value + messageId?messageId.value:'';
                } else if (inReplyTo) {
                    request.resource.References = inReplyTo.value + messageId?messageId.value:'';
                }

                if (messageId) {
                    request.resource['In-Reply-To'] = messageId.value;
                    request.resource.References = messageId.value;
                }
            }
            var sendRequest = window.gapi.client.gmail.users.messages.send(request);
            return sendRequest.execute(callback);
        },

        sendMessage: function (to, subject, message, callback)
        {
            var email = '';

            var headersObj = {
                'To': to,
                'Subject': subject
            };

            for(var header in headersObj)
                email += header += ': '+headersObj[header]+'\r\n';

            email += '\r\n' + message;

            var sendRequest = window.gapi.client.gmail.users.messages.send({
                'userId': 'me',
                'resource': {
                    'raw': window.btoa(email).replace(/\+/g, '-').replace(/\//g, '_')
                }
            });

            return sendRequest.execute(callback);
        },

        getConnectedUser: function() {
            var user;
            try {
                user = window.gapi.auth2.getAuthInstance().currentUser.Ab.w3.U3;
            } catch (err) {
            }
            if (!user) {
                user = Settings.get('currentCAccount').contactEmail;
            }
            return user;
        },

        openNewEmailInGmail: function(mailTo, subject, body) {
            this.on('gmail_utils:loaded', _.once(_.bind(function() {
                var user = this.getConnectedUser();
                window.open('https://mail.google.com/mail/u/'+user+'/?view=cm&fs=1&to='+mailTo+'&tf=1&su='+
                    encodeURI(subject)+'&body=' + encodeURI(body));
            }, this)));
            this.connect();
        },

        clearTokens: function() {
            this.paginationTokens = [];
            this.currentPaginationToken = null;
        },


        // btoa replacement
         encode: function (input)
        {
            var output = '';
            var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
            var i = 0;
            var _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

            input = this.utf8Encode(input);

            while (i < input.length)
            {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);

                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;

                if (isNaN(chr2))
                {
                    enc3 = enc4 = 64;
                }
                else if (isNaN(chr3))
                {
                    enc4 = 64;
                }

                output = output +
                  _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
                  _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
            }
            return output;
        },

        utf8Encode: function (string)
        {
            var utftext = '';
            string = string.replace(/\r\n/g, '\n');

            for (var n = 0; n < string.length; n++)
            {
                var c = string.charCodeAt(n);

                if (c < 128)
                {
                    utftext += String.fromCharCode(c);
                }
                else if ((c > 127) && (c < 2048))
                {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
                else
                {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }

            } // Next n

            return utftext;
        }

    });

    var getInstance = function() {
        if (!instance) {
            instance = new GmailUtils();
            instance.init();
        }
        return instance;
    };

    module.exports = GmailUtils;
    module.exports.getInstance = getInstance;
});

