define('orderTabsLayoutController',[
  'module',
  'orderTabsLayoutView',
  'app',
  'bootbox',
  'settings',
  'jquery',
  'underscore',
  'tabsBaseController',
  'orderTabsData',
  'orderUtils',
  'emptyView',
  'rolesMixin',
  'entities/orders',
  'entities/customers/customers'
], function (
  module,
  TabsLayoutView,
  App,
  bootbox,
  Settings,
  $,
  _,
  TabBaseController,
  tabs,
  OrderUtils,
  EmptyView,
  RolesMixin
) {
  'use strict';

  var OrderTabsLayoutController = _.extend(TabBaseController, {
    model: App.request('order:model'),
    View: TabsLayoutView,
    tabs: tabs,

    getFirstAllowedTab: function (tabName) {
      var tabs = this.cleanTabsWithRole(this.tabs);

      if (tabName) {
        var tab = _.findWhere(tabs, {name: tabName});
        if (!tab) {
          tabName = tabs[0].name;
        }
      }
      return tabName;
    },

    cleanTabsWithRole: function (tabs) {
      var allowedTabs = [];

      _.each(tabs, function (tab) {
        if (!tab.role || Settings.get('cAccountRoles')[tab.role]) {
          allowedTabs.push(tab);
        }
      });

      return allowedTabs;
    },

    fillProuctWeightAndCustomer: function (region, tabName, defer) {
      this.productWeight = this.product.get('weight');
      this.productWeightUnit = this.product.get('weightUnit');
      this.customer = App.request('customers:entity:end-user-id', this.model.get('endUserId'));
      if (this.model.get('endUserId')) {
        this.customer.fetch().done(_.bind(function (customer) {
          App.trigger('customer:comments', customer.secId);
          this.loadLayout(region, tabName, customer, this.product, defer);
          $('#customer-comments-sidebar-region').show();
        }, this));
      } else {
        this.loadLayout(region, tabName, this.customer, this.product, defer);
      }
    }, showTabsLayout: function (region, tabName, id) {
      var defer = $.Deferred();
      if (!region.currentView || region.currentView !== this._layoutView ||
        !this.model || this.model.get('purchaseOrderId') !== id || App.getCurrentRoute().indexOf('?refresh') !== -1) {
        this.tabs = this.cleanTabsWithRole(this.tabs);
        this.model = App.request('order:model');
        if (this.model.getCode && this.model.getName) {
          this.model.set(this.model.idAttribute, id);
        } else {
          throw 'Model does not have methods getCode and getName. Methods are required for filling in code ' +
          'and name properties which will be shown on the header';
        }
        var refresh = false;

        if (App.getCurrentRoute().indexOf('?refresh') !== -1) {
          refresh = true;
          App.navigate(App.getCurrentRoute().split('?')[0], {trigger: false});
        }
        if (this._layoutView && !this._layoutView.isDestroyed && !refresh &&
          this.model.get(this.model.idAttribute) === this._layoutView.model.get(this._layoutView.model.idAttribute)) {
          defer.resolve(this._layoutView.getRegion('tabs'), this._layoutView.model, this._layoutView.options.customer);
          this._layoutView.triggerMethod('show:active', tabName);
          return defer.promise();
        }

        region.show(new EmptyView());

        this.model.fetch().done(_.bind(function () {
          if (this.model.get('pim')) {
            this.product = App.request('products:model', this.model.get('productJson'));
            this.fillProuctWeightAndCustomer(region, tabName, defer);
          } else {
            this.product = App.request('products:model', {secId: this.model.get('productSecId')});
            this.product.fetch().done(_.bind(function () {
              this.fillProuctWeightAndCustomer(region, tabName, defer);
            }, this));
          }
        }, this));
      } else {
        defer.resolve(this._layoutView.getRegion('tabs'), this._layoutView.model, this.customer, this.product);
      }
      return defer.promise();
    },

    loadLayout: function (region, tabName, customer, product, defer) {
      var fetchStatuses = App.request('order:statuses', this.model.get('commandType') === 'offer');
      $.when(fetchStatuses).done(_.bind(function (statuses) {
        App.request('product:get-supplier-data', product.get('cCode')).done(_.bind(function (supplierData) {
          var productHasDifferentConf = !Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2] ?
            OrderUtils.checkPrintFromSupplier(supplierData, product, this.model.get('compositions')) : false;
          this.model.set({
            id: this.model.get(this.model.idAttribute),
            code: this.model.getCode()
          });
          this.model.set('weight', this.productWeight);
          this.model.set('weightUnit', this.productWeightUnit);
          this._layoutView = new TabsLayoutView({
            model: this.model,
            tabs: this.tabs,
            product: product,
            customer: customer,
            statuses: statuses,
            productHasDifferentConf: productHasDifferentConf
          });
          region.show(this._layoutView);
          this._layoutView.triggerMethod('show:active', tabName);
          var currentHeaderView = this._layoutView.getRegion('header').currentView;
          currentHeaderView.listenTo(currentHeaderView, 'duplicateOrder', _.bind(this.duplicateOrder, this));
          currentHeaderView.listenTo(currentHeaderView, 'toInvoice', _.bind(this.toInvoice, this));
          currentHeaderView.listenTo(currentHeaderView, 'convertPimToCompuzz', _.bind(this.convertPimToCompuzz, this));

          defer.resolve(this._layoutView.getRegion('tabs'), this._layoutView.model, customer, product);
        }, this));
      }, this));
    },

    duplicateOrder: function () {
      this._layoutView.model.duplicate().done(_.bind(function (e) {
        App.navigate('orders/' + e.value, {trigger: true});
      }, this));
    },

    toInvoice: function () {
      OrderUtils.canCreateInvoice(this.model.get('payedByCustomer')).done(_.bind(function (canSend) {
        if (canSend) {
          $.when(App.request('order:generate-invoice', this._layoutView.model.get('purchaseOrderId')))
            .done(_.bind(function () {
              this._layoutView.model.set('toInvoice', true);
              App.navigate(App.getCurrentRoute() + '?refresh', {trigger: true});
            }, this)).fail(function () {
            bootbox.dialog({
              title: _.i18n('common.error'),
              message: 'VERIFY CUSTOMER DATA'
            });
          });
        }
      }, this));
    },

    convertPimToCompuzz: function () {
      $.when(this._layoutView.model.convertPimToCompuzz())
        .done(_.bind(function () {
          App.navigate(App.getCurrentRoute() + '?refresh', {trigger: true});
        }, this)).fail(function () {
        bootbox.dialog({
          title: _.i18n('common.error'),
          message: 'Supplier product data not matching PIM data'
        });
      });
    }
  });

  App.commands.setHandler('orders:status:change', function () {
    OrderTabsLayoutController._layoutView.getRegion('header').currentView.triggerMethod('orderStatusChange');
  });

  module.exports = OrderTabsLayoutController;
});

